"use client";

import { createContext, useContext, useMemo } from "react";
import { APIClient } from "./client";
import { useRouter } from "next/navigation";
export const APIContext = createContext<APIClient | null>(null);
export const APIProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const apiClient = useMemo(() => new APIClient(router), [router]);
  return <APIContext.Provider value={apiClient} data-sentry-element="unknown" data-sentry-component="APIProvider" data-sentry-source-file="provider.tsx">{children}</APIContext.Provider>;
};
export const useAPI = () => {
  const context = useContext(APIContext);
  if (!context) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
};