import { useQuery, useQueryClient } from '@tanstack/react-query'
import { User } from '@supabase/supabase-js'
import { useSupabase } from '../utils/supabase/client'

export function useUser() {
  const supabase = useSupabase()
  const queryClient = useQueryClient()

  const fetchUser = async (): Promise<User | null> => {
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser()
    if (error) throw error

    if (user) {
      const { data: profile } = await supabase
        .from('profiles')
        .select(`avatar_url, username, full_name, website`)
        .eq('id', user.id)
        .single()

      return {
        ...user,
        user_metadata: {
          ...user.user_metadata,
          ...profile,
        },
      }
    }

    return null
  }

  const {
    data: user,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    staleTime: 1000 * 60 * 5, // 5分钟
    refetchOnWindowFocus: false,
  })

  const refetch = () => queryClient.invalidateQueries({ queryKey: ['user'] })

  return {
    user,
    loading: isLoading,
    hasLogin: !!user,
    error,
    refetch,
  }
}

export function useSignOut() {
  const supabase = useSupabase()
  const queryClient = useQueryClient()

  return async () => {
    await supabase.auth.signOut()
    queryClient.setQueryData(['user'], null)
  }
}
