'use client';

import { FormEvent, useCallback, useState, useRef } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, Loader2Icon, ImageIcon, XIcon } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import ShimmerButton from '../magicui/shimmer-button';
import { cn } from '@/lib/utils';
import { IconBtn } from '../IconBtn';
export const PromptBarUI: React.FC<{
  className?: string;
  mode: 'create' | 'edit';
  prompt: string;
  setPrompt: (prompt: string) => void;
  onGenerate: (prompt: string, image: string | null) => void;
  onEdit?: (prompt: string, image: string | null) => void;
  loading: boolean;
  onBack?: () => void;
}> = ({
  className,
  mode,
  prompt,
  setPrompt,
  onGenerate,
  onEdit,
  loading,
  onBack
}) => {
  const [focused, setFocused] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleCreate = useCallback((e: FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (!prompt) {
      alert('Please enter something you wanna create');
      return;
    }
    onGenerate(prompt, image);
  }, [loading, onGenerate, prompt]);
  const handleEdit = useCallback((e: FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (!prompt) {
      alert('Please enter something you wanna change');
      return;
    }
    onEdit?.(prompt, image);
  }, [loading, onEdit, prompt]);
  const handleImageUpload = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await fetch('/api/images/upload', {
          method: 'POST',
          body: formData
        });
        if (!response.ok) {
          throw new Error('Upload failed');
        }
        const data = await response.json();
        setImage(data.imageUrl);
        toast.success('Image uploaded successfully');
      } catch (error) {
        console.error('Image uploaded failed:', error);
        toast.error('Image uploaded failed');
      } finally {
        setUploading(false);
      }
    }
  }, []);
  const removeImage = useCallback(() => {
    setImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, []);
  const triggerFileInput = useCallback(() => {
    console.log('triggerFileInput');
    console.log(fileInputRef.current);
    fileInputRef.current?.click();
  }, []);
  const onSubmit = useCallback((e: FormEvent) => {
    if (mode === 'create') {
      handleCreate(e);
    } else {
      handleEdit(e);
    }
  }, [mode, handleCreate, handleEdit]);
  return <div className={cn('pl-3 flex shadow-2xl rounded-full items-center ring-1 ring-inset ring-gray-300 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-2 transition-all duration-200', focused && 'ring-2 ring-ring', className)} data-sentry-component="PromptBarUI" data-sentry-source-file="index.tsx">
      {onBack && <IconBtn disabled={loading} className="rounded-full" variant="ghost" title="Back to last version" Icon={ChevronLeftIcon} onClick={onBack} />}
      <div className="w-full flex items-center">
        <form onSubmit={onSubmit} className="w-full flex items-center">
          <Input type="text" value={prompt} onChange={e => setPrompt(e.target.value)} placeholder={mode === 'create' ? 'Enter what you want to create' : 'Enter what you want to improve next'} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} className="w-full h-12 pl-3 border-none bg-transparent focus-visible:ring-offset-0 focus-visible:ring-0" data-sentry-element="Input" data-sentry-source-file="index.tsx" />
        </form>

        {image ? <div className="relative m-2">
            <img src={image} alt="Upload Image" className="w-8 h-8 object-cover rounded" />
            <button type="button" onClick={removeImage} className="absolute -top-1 -right-1 bg-red-500 rounded-full p-0.5">
              <XIcon className="w-3 h-3 text-white" />
            </button>
          </div> : <IconBtn className="m-2" variant="ghost" title="Upload image" Icon={ImageIcon} onClick={triggerFileInput} disabled={uploading} />}
        <input type="file" ref={fileInputRef} className="hidden" accept="image/*" onChange={handleImageUpload} disabled={uploading} />
      </div>

      {mode === 'create' && <ShimmerButton type="submit" className="rounded-l-none rounded-r-full transition-all duration-200 w-24 h-12" onClick={handleCreate} disabled={loading}>
          {loading ? <span className="group inline-flex items-center whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg">
              <Loader2Icon className="h-4 w-4 animate-spin" />
            </span> : <span className="group inline-flex items-center whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg">
              GO{' '}
              <ChevronRightIcon className="ml-1 h-4 w-4 transition-transform duration-300 group-hover:translate-x-1" />
            </span>}
        </ShimmerButton>}

      {mode === 'edit' && <ShimmerButton type="submit" className="rounded-l-none rounded-r-full transition-all duration-200 w-32 h-12 justify-between" onClick={handleEdit} disabled={loading}>
          {loading ? <span className="group w-full inline-flex items-center justify-center whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg">
              <Loader2Icon className="h-4 w-4 animate-spin" />
            </span> : <div className="group w-full flex items-center justify-center whitespace-pre-wrap text-center text-sm font-normal leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg">
              <div className="flex items-center justify-center">Improve</div>
            </div>}
        </ShimmerButton>}
    </div>;
};