import React from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import { cn } from '@/lib/utils';
import { buttonVariants } from '../ui/button';
import { LucideIcon } from 'lucide-react';
export const IconBtn: React.FC<{
  className?: string;
  title: string;
  label?: string;
  variant?: 'ghost' | 'default';
  Icon?: LucideIcon | React.FC<React.SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  href?: string; // 新增的 href 属性
}> = ({
  className,
  title,
  label,
  variant = 'default',
  Icon,
  disabled,
  onClick,
  children,
  href // 新增的 href 属性
}) => {
  const ButtonOrLink = href ? 'a' : 'button';
  return <Tooltip delayDuration={0} data-sentry-element="Tooltip" data-sentry-component="IconBtn" data-sentry-source-file="index.tsx">
      <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="index.tsx">
        <ButtonOrLink className={cn(buttonVariants({
        variant,
        size: 'icon'
      }), 'h-9 w-auto px-3 min-w-9 flex items-center', variant === 'default' && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white', disabled && 'opacity-50 cursor-not-allowed', className)} onClick={disabled ? undefined : onClick} href={href} // 新增的 href 属性
      data-sentry-element="ButtonOrLink" data-sentry-source-file="index.tsx">
          {Icon && <Icon className="h-4 w-4 mr-2" />}
          {children}
          <span className="sr-only">{title}</span>
        </ButtonOrLink>
      </TooltipTrigger>
      <TooltipContent side="top" className="flex items-center gap-4" data-sentry-element="TooltipContent" data-sentry-source-file="index.tsx">
        {title}
        {label && <span className="ml-auto text-muted-foreground">{label}</span>}
      </TooltipContent>
    </Tooltip>;
};